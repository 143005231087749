import { get, set } from 'idb-keyval';
import {useRef} from 'react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { indexedDBEffect } from '../GlobalState';

const PageInfo = atom({
    key: 'pageInfo',
    default: {count:0, snackOpen: false, isLoading: false, recordCount: 0},
  });
  
  const MaritimeData = atom({
    key: 'maritimeData',
    default: {},
    effects: [
      indexedDBEffect('maritimeData')
    ]
  });

  const MaritimeHistoryData = atom({
    key: 'maritimeHistoryData',
    default: false
});
  
  const StopMaritimeData = atom({
    key: 'stopMaritimeData',
    default: false,
  });

  const MaritimeLayer = atom({
    key: 'MaritimeLayer',
    default: false,
  });

  const ClickInfo = atom({
    key: 'clickInfo',
    default: [],
  });

  export {PageInfo, MaritimeData, StopMaritimeData, MaritimeLayer, ClickInfo, MaritimeHistoryData}