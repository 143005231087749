import React, { useEffect } from 'react';
import { DeckLayers, HoverInfo, ViewState } from '../GlobalState';
import {
  EditableGeoJsonLayer,
} from '@nebula.gl/layers';
import {DrawRectangleMode} from '@nebula.gl/edit-modes'
import { useRecoilState } from 'recoil';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';


import {
  ViewMode,
  DrawRectangleMode,
  ExtrudeMode,
  ScaleMode
} from '@nebula.gl/edit-modes';

import { Features } from './EditState';





const GeometryEditor = () => {

  const [features, setFeatures] = useRecoilState(Features);
  const [mode, setMode] = React.useState(() => DrawRectangleMode);
  const [modeConfig, setModeConfig] = React.useState({});
  const [selectedFeatureIndexes, setSelectedFeatureIndexes] = React.useState(
    []
  );
  

  const [layer, setLayers] = useRecoilState(DeckLayers)


  let [isEditGeography,setIsEditGeography] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };



  useEffect(()=>{
    if (isEditGeography){
      //console.log(isEditGeography)
        const editLayer = new EditableGeoJsonLayer({
          id: "geojson-layer",
          data: features,
          mode: DrawRectangleMode,
          selectedFeatureIndexes,
          onEdit: ({ updatedData }) => {
            if(updatedData.features.length >0 ){
              setFeatures((prev) => {console.log(updatedData); return updatedData});
            }
          }
        });

        setLayers((prev)=> [...prev.filter(d =>  d.id != 'geojson-layer'), editLayer])
      } else {
        console.log("Remove Layer")
        setLayers((prev)=> [...prev.filter(d =>  d.id != 'geojson-layer')])
      }
    
  }, [features, isEditGeography])

  return (
    <React.Fragment>
    <IconButton
    size="large"
    edge="end"
    color="inherit"
    aria-label="menu"
    sx={{ mr: 2}}
    onClick={handleClick}
  >
    <HighlightAltIcon />
  </IconButton>
  <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={handleClose}>Manage Polygons</MenuItem>
      <MenuItem onClick={handleClick2}>Edit </MenuItem>
      <MenuItem onClick={handleClose}>Logout</MenuItem>
   </Menu>
   <Menu
      id="basic-menu"
      anchorEl={anchorEl2}
      open={open2}
      onClose={handleClose2}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={handleClose2}>Manage Polygons</MenuItem>
      <MenuItem onClick={handleClose2}>Edit </MenuItem>
      <MenuItem onClick={handleClose2}>Logout</MenuItem>
   </Menu>
   </React.Fragment>
  )
}



export {GeometryEditor}