import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';

import { get, set } from 'idb-keyval';
import React, { useState, useEffect } from 'react';
import {PageInfo, MaritimeData, StopMaritimeData, MaritimeLayer, ClickInfo} from './MaritimeState'
import {CardPortal, DeckLayers, HoverInfo, MaritimeAPIToken} from '../GlobalState'
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { cardActionAreaClasses } from '@mui/material';
import Portal from '@mui/base/Portal';
import MaritimeCard from './MaritimeCard';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import vessel from 'data-url:./vessel.svg'
import chroma from 'chroma-js'


import {IconLayer} from '@deck.gl/layers'

const ICON_MAPPING = {
  vessel: {x:0, y:0, width:12, height:24, mask:true}
}

let abortMaritimeData = new AbortController()

let getAllVessels = ({nextCursor="", abortMaritimeData, maritimeAPIToken, searchParameters = {}, vesselToPortETA = false, evd = false}) => {
    let name = searchParameters?.name || '';
    let mmsi = searchParameters?.mmsi || '';
    let imo = searchParameters?.imo || '';
    let callsign = searchParameters?.callsign ||''



    let defaultDate = searchParameters?.lastPositionUpdateStart || new Date(new Date().setDate((new Date().getDate() - 1))).toISOString();
    //let defaultDate = new Date(new Date().setMinutes((new Date().getMinutes() - 1))).toISOString();

    let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${maritimeAPIToken}`);
        myHeaders.append("Content-Type", "application/json");

    let variables = {"first":"1000", 
                      "after":nextCursor, 
                      "lastPositionUpdateStart":defaultDate}

    
    for (const [k,v] of Object.entries(searchParameters)){
      if (v != '') {
        if (v?.length > 0 && typeof(v) != 'string') {
          console.log(v)
          variables[k] = v.map(d => parseInt(v)|| v)
        } else {
          variables[k] = (k == 'lastPositionUpdateStart') ? v : parseInt(v) || v
        }
      }
    }

    
    
    let query = JSON.stringify(
      {query:`query($first: Int!, $after: String!, $lastPositionUpdateStart: DateTime!, 
        ${ name != '' ? `$name: [String!]`: ''}
        ${ callsign != '' ? `$callsign: [String!]`: ''}
        ${ mmsi != '' ? `$mmsi: [MMSI!]`: ''}
        ${ imo != '' ? `$imo: [IMO!]`: ''}
        )
       {  vessels(first: $first, 
                  after: $after,
                  ${ name != '' ? 'name: $name,' : ''}
                  ${ callsign != '' ? 'callsign: $callsign,' : ''}
                  ${ mmsi != '' ? 'mmsi: $mmsi,' : ''}
                  ${ imo != '' ? 'imo: $imo,' : ''}
                  lastPositionUpdate: {
                    startTime: $lastPositionUpdateStart
                  })  {
        pageInfo {
                hasNextPage
                endCursor    
                }    
          totalCount{ 
                  relation 
                  value 
               }    
          nodes {
                  staticData {
                              name
                              mmsi
                              imo
                              shipType
                              callsign
                    ${ evd ? `
                              shipSubType
                              flag
                    ` : ''}
                    
                    }      
                    lastPositionUpdate {
                              timestamp
                              latitude
                              longitude
                              collectionType
                              heading
                                    }
                    ${ evd ? `
                    characteristics {
                      extended {
                        capacity {
                          deadweight
                          tpcmi
                          netTonnage
                          grossTonnage
                          displacement
                          liquidCubic98Percent
                          grainCubicCapacity
                          teu
                          holdCount
                          holdDimensions
                          hatchCount
                          hatchDimensions
                          feu
                          teuSurplus
                          teu14t
                          laneMeters
                          cars
                          passengers
                          reeferCubic
                        }
                        design {
                          isCoated
                          isGearless
                          isSelfUnloading
                          gearDisplay
                          gearMaxSwl
                          reeferPointCount
                          hullTypeCode
                        }
                        dimensions {
                          draught
                          lengthOverall
                          airDraught
                          keelToManifold
                          depth
                          beamMoulded
                          berthCount
                        }
                        history {
                          vesselNameDate
                          builtYear
                          deadYear
                          shipBuilder
                          hullNumber
                          registeredOwner
                          keelLaidYear
                          launchYear
                          commercialOwner 
                        }
                        propulsion {
                          mainEngineCount
                          mainEngineDesigner
                          propulsionType
                          engineDesignation
                          mcoRpm
                          mcoKw
                          mcoHp
                          propellerCount
                          propellerType
                          bowThrusterCount
                          sternThrusterCount
                        }
                        registration {
                          class1Code
                          class2Code
                          classDetails
                          isIceClassed
                          iceClass
                          #lastSpecialSurvey
                          #lastDryDock
                          certificates
                        }
                        vesselTypeAndTrading {
                          vesselSubtype
                          tradingCategoryCode
                          tradingStatusCode
                        }
                        bunker {
                          bunkers {
                            capacity
                            fuelTypeCode
                            fuelUnitCode
                            tankCount
                          }
                          range
                        }
                      }
                    }
                    `:''}
                    ${ vesselToPortETA ? `
                      currentVoyage{
                        predictedRoute{
                          eta
                          distance
                          duration {
                            iso
                          }
                          destinationPort{
                            name
                          }
                          waypoints {
                            geoJson{
                              coordinates
                            }
                          }
                        }
                      }
                    `:``}    
                    }  
                  }
                }`,
              variables: variables})
  
    let requestOptions = {
      signal: abortMaritimeData.signal,
      method: 'POST',
      headers: myHeaders,
      body: query,
      redirect: 'follow',
      mode: 'cors',
    };
  
    return requestOptions
  }
  
  
  
  let getMaritimeData = async ({setMaritimeData, setPageInfo ,nextCursor="", abortMaritimeData = new AbortController(), maritimeAPIToken, searchParameters, vesselToPortETA = false, evd = false}) => {
    try {
    let response = await fetch("/graphql", getAllVessels({nextCursor, abortMaritimeData, maritimeAPIToken, searchParameters, vesselToPortETA, evd}))
    let result = await response.json();
    let data = result.data.vessels.nodes


    setMaritimeData((state) => {
      let priorData = structuredClone(state)
      for (record of data) {
            priorData[record.staticData.mmsi] = record;
      }  

      return priorData;
    })
      
    if (result.data.vessels.pageInfo.hasNextPage){
      if(typeof(setPageInfo) != "undefined" ){
        setPageInfo((state, props)=> {
              return {count: state.count + 1 , totalCount: Math.ceil(result.data.vessels.totalCount.value/1000), snackOpen:true, isLoading:true}
        });
      }

      return getMaritimeData({setMaritimeData, nextCursor:result.data.vessels.pageInfo.endCursor, setPageInfo, abortMaritimeData, maritimeAPIToken, searchParameters});
    }
    else {
      return data;
    }
    }
    catch (e){
      console.log(`Error fetching Maritime Data:`, e)
    }
  }
  
  let [FISHING, TUG, DRY_BULK, CONTAINER, OTHER, PASSENGER, GENERAL_CARGO, TANKER_PRODUCT, CAR_CARRIER, PLEASURE_CRAFT, GAS_CARRIER, OFFSHORE, TANKER_CRUDE, PILOT_VESSEL, SEARCH_AND_RESCUE, VEHICLE_PASSENGER, ROLL_ON_ROLL_OFF, TANKER_CHEMICALS, SAILING, HIGH_SPEED_CRAFT, ANTI_POLLUTION, REEFER, MILITARY_OPS, DREDGER, LAW_ENFORCEMENT, LNG_CARRIER, PORT_TENDER, GENERAL_TANKER, DIVE_VESSEL, SPECIAL_CRAFT, LIVESTOCK] = chroma.scale(['blue','red']).colors(33, format='rgb')
  
  let vesselColors = {FISHING, TUG, DRY_BULK, CONTAINER, OTHER, PASSENGER, GENERAL_CARGO, TANKER_PRODUCT, CAR_CARRIER, PLEASURE_CRAFT, GAS_CARRIER, OFFSHORE, TANKER_CRUDE, PILOT_VESSEL, SEARCH_AND_RESCUE, VEHICLE_PASSENGER, ROLL_ON_ROLL_OFF, TANKER_CHEMICALS, SAILING, HIGH_SPEED_CRAFT, ANTI_POLLUTION, REEFER, MILITARY_OPS, DREDGER, LAW_ENFORCEMENT, LNG_CARRIER, PORT_TENDER, GENERAL_TANKER, DIVE_VESSEL, SPECIAL_CRAFT, LIVESTOCK}


  let MaritimeDataComponent= ()=>{
    const [pageInfo,setPageInfo] = useRecoilState(PageInfo);
    const [maritimeData,setMaritimeData] = useRecoilState(MaritimeData);
    const [stopMaritimeData,setStopMaritimeData] = useRecoilState(StopMaritimeData);
    const [maritimeLayer,setMaritimeLayer] = useRecoilState(MaritimeLayer);
    const [cardPortal, setCardPortal] = useRecoilState(CardPortal);
    const [displayMaritimeOptions, setDisplayMaritimeOptions] = useState(false)
    const [maritimePage,setMaritimePageInfo] = useRecoilState(PageInfo)
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [hoverInfo, setHoverInfo] = useRecoilState(HoverInfo);
    const [clickInfo, setClickInfo] = useRecoilState(ClickInfo);
    const [maritimeAPIToken] = useRecoilState(MaritimeAPIToken);
    const [intervalId, setIntervalId] = useState(0)
    const [syntheticStream, setSyntheticStream] = useState(false)

    let handleChange = ()=> {
      setStopMaritimeData((state)=> {
        if(state) {
          abortMaritimeData.abort()
          abortMaritimeData = new AbortController()
          return !state;
        } else {
          getMaritimeData({setMaritimeData,setPageInfo, abortMaritimeData, maritimeAPIToken}).then(()=>{
            setPageInfo({count:0, snackOpen: false, isLoading:false, recordCount:0});
            console.log("Data Fetch Complete")
          })
          return !state;
        }
      })
    }

    let handleSyntheticStream = ()=> {
      setMaritimeData({})
      setSyntheticStream((state)=> {
        if(state) {
          abortMaritimeData.abort()
          abortMaritimeData = new AbortController()
          window.clearInterval(intervalId);

          return !state;
        } else {
          searchParameters =  {}
          searchParameters.lastPositionUpdateStart = new Date(new Date().setMinutes((new Date().getMinutes() - 1))).toISOString();

          getMaritimeData({setMaritimeData,setPageInfo, abortMaritimeData, maritimeAPIToken, searchParameters}).then(()=>{
            setPageInfo({count:0, snackOpen: false, isLoading:false, recordCount:0});
            console.log("Data Fetch Complete")
          })
          
          if (intervalId == 0) {
            let id = window.setInterval(()=>
            {
            searchParameters =  {}
            searchParameters.lastPositionUpdateStart = new Date(new Date().setMinutes((new Date().getMinutes() - 1))).toISOString();
    
            getMaritimeData({setMaritimeData,setPageInfo, abortMaritimeData, maritimeAPIToken, searchParameters}).then(()=>{
              setPageInfo({count:0, snackOpen: false, isLoading:false, recordCount:0});
              console.log("Data Fetch Complete")
            })},300000)
            setIntervalId(id)
          }
          return !state;
      }
      })
    }

    let handleLayerChange = ()=> {
      setMaritimeLayer((state)=> {
        return !state;
      })
    }

    let handleDisplayMaritimeOptions = () => {
      setDisplayMaritimeOptions(prev => !prev)
    }

    useEffect(()=> {
      setMaritimePageInfo((state)=>{
        let prior = structuredClone(state);
        prior.recordCount = Object.keys(maritimeData).length
        return prior
      })

      if(maritimeLayer) {
            const newlayer = 
            new IconLayer({id: 'maritime-layer', 
                        data: Object.values(maritimeData),     //Map object
                        sizeScale:8,
                        pickable: true,
                        iconAtlas: vessel,
                        iconMapping: ICON_MAPPING,
                        sizeMaxPixels: 6,
                        sizeMinPixels: 5, 
                        getIcon: d => 'vessel',
                        getColor: d => vesselColors[d.staticData.shipType]||[0,0,0],
                        getPosition: value => [value.lastPositionUpdate.longitude, value.lastPositionUpdate.latitude], //using Map() iterable
                        getAngle: value =>  value.lastPositionUpdate?.heading || 0,
                        onHover: (info,event) => {
                          setHoverInfo(info)
                        },
                        onClick: (info, event)=>{
                          let record = structuredClone(info.object)
                          setClickInfo((prev)=> [...prev.filter( d=>d.staticData.mmsi != record.staticData.mmsi), record]);
                        }
                      })
          ;
          setLayers((prev)=> [...prev.filter(d => d.id != 'maritime-layer'), newlayer]);
        } else {
          setLayers((prev) => {
            return prev.filter(d => d.id != 'maritime-layer')
          });
        }
    }
  , [maritimeData,maritimeLayer]);

   return (
     <React.Fragment>
        <Button variant="text" color="secondary" onClick={handleDisplayMaritimeOptions}>Maritime 2.0</Button>
        {cardPortal && displayMaritimeOptions ? <Portal container={cardPortal.current}>
        <Card><CardContent>
         <FormGroup>
          <FormControlLabel control={
            <Switch
              checked={stopMaritimeData}
              onChange={handleChange}
              color='info'
              
            />
          } label="Maritime 2.0 Data" />
          </FormGroup>
          <FormGroup>
          <FormControlLabel control={
            <Switch
              checked={syntheticStream}
              onChange={handleSyntheticStream}
              color='info'
              
            />
          } label="Start Synthetic Stream" />
          </FormGroup>
          <FormGroup>
          <FormControlLabel control={
            <Switch
              checked={maritimeLayer}
              onChange={handleLayerChange}
              color='info'
              
            />
          } label="Maritime 2.0 Layer" />
          
          </FormGroup>
          </CardContent></Card>
          </Portal> : '' }
          {cardPortal && clickInfo.length > 0 ? <Portal container={cardPortal.current}>
            <MaritimeCard/>
          </Portal> : ''}
          </React.Fragment>)
  } 

  export {MaritimeDataComponent, getMaritimeData}