import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';

import { get, set } from 'idb-keyval';
import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { cardActionAreaClasses } from '@mui/material';
import Portal from '@mui/base/Portal';
import {AviationData, AviationClickInfo} from './AviationState'
import { DeckLayers } from '../GlobalState';
import {ScatterplotLayer, IconLayer, LineLayer} from '@deck.gl/layers';
import {CardPortal} from '../GlobalState'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import {AviationCard} from './AviationCard'
import {AviationAPIToken} from '../GlobalState'
import airplane from 'data-url:./airplane.svg'

let aviationAbortController = new AbortController();

const ICON_MAPPING = {
  airplane: {x:0, y:0, width:24, height:10, mask:true}
}

let extractJSON = (string) => {
    const results = string.match(/\{(?:[^{}])*\}/g);
    return results || [];
  }

let aviationStream = async ({setAviationData, signal, aviationAPIToken}) => {
  try {
    let response = await fetch(
        `https://api.airsafe.spire.com/v2/targets/stream?compression=none&late_filter=true`,
        {
          signal,
          headers: {
            Authorization: aviationAPIToken,
        },
        }
      )

      const stream = response.body.getReader();

      let AviationMap = {}
    
      while (true) {
            const {value, done} = await stream.read();
            if (done) {
                break;
            }
      
            try {
                extractJSON(new TextDecoder("utf-8").decode(value)).forEach(
                    (parsed)=> {
                        if (parsed.indexOf("icao_address") > 0) {
                            const record = JSON.parse(parsed);
                            AviationMap[record['icao_address']] = record;
                          }
                    }
                ,[]);
                setAviationData((prevData)=>  {
                    let prevMap = structuredClone(prevData);
                    
                    for ( const [key,values] of Object.entries(AviationMap)) {
                        prevMap[key] = values;
                        
                    }
                    
                        return prevMap;
                    }
                );
            } catch (e) {
                console.log(`An error occured while parsing stream results: ${e}`);
            }
    }
  } catch (e) {
    console.log(`Unable to connect to Aviation Stream: ${e}`)
    return;
  }
    }

let aviationClickHandler = (info)=>{
  let record = structuredClone(info.object)
  setAviationClickInfo((prev)=> {
    let prevData = [...prev.filter(d => record.icao_address != d.icao_address), record]
    return prevData;
  });
}

let AviationDataComponent = ()=>{
    const [aviationData,setAviationData] = useRecoilState(AviationData);
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [visibilty, setVisibility] = useState(false);
    const [cardPortal, setCardPortal] = useRecoilState(CardPortal);
    const [displayAviationOptions, setDisplayAviationOptions] = useState(false)
    const [isAviationStream, setIsAviationStream] = useState(false)
    const [aviationClickInfo, setAviationClickInfo] = useRecoilState(AviationClickInfo);
    const [aviationAPIToken] = useRecoilState(AviationAPIToken);

    let aviationClickHandler = (info)=>{
      let record = structuredClone(info.object)
      
      setAviationClickInfo((prev)=> {
        let prevData = [...prev.filter(d => record.icao_address != d.icao_address), record]
        return prevData;
      });
    }
    
    let handleDisplayAviationOptions = () => {
      setDisplayAviationOptions(prev => !prev)
    }

    let handleChange = ()=> {
        if(!visibilty){
            const newlayer = 
                new IconLayer({id: 'aviation-layer', 
                            data: Object.values(aviationData),
                            sizeScale:5,
                            iconAtlas: airplane,
                            iconMapping:ICON_MAPPING,
                            getIcon: d => 'airplane',
                            pickable: true,
                            getFillColor: [255,0,0],
                            getPosition: (value) =>  [value.longitude, value.latitude, value.altitude_baro / .3048], 
                            getAngle: value => value.heading,
                            /*onHover: (info,event) => {
                              setHoverInfo(info)
                            },*/
                            onClick: (info, event)=> aviationClickHandler(info)
                          })
              ;
            setLayers((prev)=> [...prev.filter(d => d.id != 'aviation-layer'), newlayer]);
        } else {
            setLayers((prev) => {
              prev = prev.filter(d => d.id != 'aviation-layer')
              return prev
            });
        }
        setVisibility((prev) => !prev)
    }

    let handleAbort = ()=>{
         aviationAbortController.abort()
         aviationAbortController = new AbortController();
    }   

    let handleAviationStream = () => {
        setIsAviationStream((prev)=>{
          if(prev){
            handleAbort()
          } else {
            aviationStream({setAviationData, signal:aviationAbortController.signal, aviationAPIToken})
          }
          return !prev;
        })
    }

    return (
        <React.Fragment>
              <Button variant="text" color="secondary" onClick={handleDisplayAviationOptions}>Aviation</Button>
              {cardPortal && displayAviationOptions ? <Portal container={cardPortal.current}>
              <Card><CardContent>
                <Stack spacing={2}>
                  {`Aviation Data: ${Object.keys(aviationData||{}).length}`}
                <FormGroup>
                <FormControlLabel control={
                  <Switch
                    checked={isAviationStream}
                    onChange={handleAviationStream}
                    color='info'
                    
                  />
                } label="Aviation Stream" />
                <FormControlLabel control={
                  <Switch
                    checked={visibilty}
                    onChange={handleChange}
                    color='info'
                    
                  />
                } label="Aviation Layer" />
                </FormGroup>
                </Stack>
            </CardContent></Card>
            </Portal> : '' }
            { cardPortal && aviationClickInfo.length ? <Portal container={cardPortal.current}>
                <AviationCard/>
            </Portal> : '' }
        </React.Fragment>)
     }
   
     export {AviationDataComponent}

