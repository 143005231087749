import * as React from 'react';
import {atom, selector, useRecoilState} from 'recoil';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CircularProgress from '@mui/material/CircularProgress';
import SettingsIcon from '@mui/icons-material/Settings';
import {CardPortal} from './GlobalState'
import {Settings} from './Settings'
import Portal from '@mui/base/Portal';
import {Search} from './Search'
import { GeometryEditor } from './Edit/Edit';
import SearchIcon from '@mui/icons-material/Search';



export default function DemoAppBar() {
  let [cardPortal] = useRecoilState(CardPortal)
  let [isSettings,setIsSettings] = React.useState(false);
  



  return (
    <Box sx={{ flexGrow: 1, height:40 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            Spire Federal - API Demo
          </Typography>
          <div style={{flexGrow:2}}></div>
           <Search/>
          <div style={{flexGrow:4}}></div>
            {/*<GeometryEditor/>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2}}
              onClick={()=> setIsSettings((prev)=> !prev) }
            >
              <SearchIcon/>
              </IconButton>*/}
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2}}
              onClick={()=> setIsSettings((prev)=> !prev) }
            >
              <SettingsIcon />
            </IconButton>
        </Toolbar>
      </AppBar>
      {cardPortal && isSettings ? <Portal container={cardPortal.current}>
            <Settings/>
          </Portal> : ''}
    </Box>
  );
}