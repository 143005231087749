let extractJSON = (string) => {
    const results = string.match(/\{(?:[^{}])*\}/g);
    return results || [];
  }



let getAviationHistory = async ({icao, setAviationHistoryData, aviationAPIToken}) => {
    let endDate = new Date();
    let startDate = new Date(new Date().setHours(endDate.getHours() - 6))
    try {
      let response = await fetch(
          `https://api.airsafe.spire.com/v2/targets/history?icao_address=${icao}&start=${startDate.toISOString()}&end=${endDate.toISOString()}`,
          {
            headers: {
              Authorization: aviationAPIToken,
          }});

          const stream = response.body.getReader();

          let AviationMap = {}
    
          while (true) {
            const {value, done} = await stream.read();
            if (done) {
              break;
            }
      
            try {
                extractJSON(new TextDecoder("utf-8").decode(value)).forEach(
                    (parsed)=> {
                        if (parsed.indexOf("icao_address") > 0) {
                            const record = JSON.parse(parsed);
                            if(typeof(AviationMap[record['icao_address']]) == 'undefined') {
                              AviationMap[record['icao_address']] = record
                              AviationMap[record['icao_address']]['path'] = [[record.longitude,record.latitude, record.altitude_baro / .3048]]
                            } else {
                              AviationMap[record['icao_address']]['path'] = [...AviationMap[record['icao_address']]['path'], [record.longitude, record.latitude, record.altitude_baro *.3048]]
                            }
                          }
                    }
                ,[]);
              }
              catch (e){
                console.log(`Error in the Aviation History Stream: ${e}`)
              }

          }
          setAviationHistoryData(AviationMap);

    } catch (e) {
      console.log(`Unable to connect to Aviation History: ${e}`)
      return;
    }
}

export {getAviationHistory}