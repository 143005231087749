{
    "version": 8,
    "sources": {
      "openseamap": {
        "type": "raster",
        "tiles": [
          "https://stamen-tiles.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png"
        ],
        "tileSize": 256
      }
    },
    "layers": [
      {
        "id": "openseamaplayer",
        "source": "openseamap",
        "type": "raster"
      }
    ]
  }