
import React, { useState, useEffect } from 'react';
import {atom, selector, useRecoilState} from 'recoil';

import DemoAppBar from './DemoAppBar'
import DeckGL from '@deck.gl/react';
import {ScatterplotLayer, LineLayer} from '@deck.gl/layers';
import 'maplibre-gl/dist/maplibre-gl.css';
import Map from 'react-map-gl';
import maplibregl, { SourceFeatureState } from 'maplibre-gl';
import MapStyle from './style.json'
import { Calculate } from '@mui/icons-material';
import DemoBottomBar from './DemoBottomBar';
import {PageInfo, MaritimeData, StopMaritimeData, MaritimeLayer, ClickInfo} from './Maritime/MaritimeState'
import {CardPortal} from './GlobalState'
import Portal from '@mui/base/Portal';
import Stack from '@mui/material/Stack';
import { DeckLayers, HoverInfo, ViewState } from './GlobalState';





  

  
  // DeckGL react component
  export function App() {
    const [hoverInfo, setHoverInfo] = useRecoilState(HoverInfo);
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [cardPortal, setCardPortal] = useRecoilState(CardPortal);
    const [viewState, setViewState] = useRecoilState(ViewState)
    const portalRef = React.useRef(null)
    const [selectedFeatureIndexes] = React.useState([]);
    
    useEffect(()=>{
      setCardPortal(portalRef);
    },[portalRef])
    



    return (
      <React.Fragment>
            <DemoAppBar />
            <DeckGL
            initialViewState={viewState}
            controller={true}
            layers={layers}
            pickingRadius={10}
            style={{height: `calc(100% - 96px)`, top:'48px'}}>
                <Map mapLib={maplibregl} mapStyle={MapStyle} />
                {hoverInfo.object && (
                  <div style={{position: 'absolute', zIndex: 1, pointerEvents: 'none', left: hoverInfo.x, top: hoverInfo.y}}>
                    { hoverInfo.object?.staticData?.name || hoverInfo.object?.vessel_name}
                </div> )}
                {hoverInfo.object && (hoverInfo.layer.id == 'dgv-event-layer-reported' || hoverInfo.layer.id == 'dgv-event-layer-estimated')  && (
                  <div style={{position: 'absolute', zIndex: 1, pointerEvents: 'none', left: hoverInfo.x, top: (hoverInfo.y + 13)}}>
                    { hoverInfo.layer.id == 'dgv-event-layer-reported' ? 'Reported' : 'Estimated'}
                </div> )}
            </DeckGL>
            <DemoBottomBar/>
            <Stack ref={portalRef} sx={{position:'absolute', margin:'30px', zIndex:'10', maxHeight: `calc(100% - 96px)`, flexWrap:'wrap', gap: 2}}/>
      </React.Fragment>
    )
  }