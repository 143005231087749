import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';
  import { indexedDBEffect } from '../GlobalState';
  
  const Features = atom({
      key: 'Features',
      default: {"type":"FeatureCollection","features":[]},
      effects:[
        ({onSet})=> {
            onSet((newValue)=> console.log(newValue))
        }
      ]
    });
    
export {Features}