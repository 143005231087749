import React from "react"
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState,
  } from 'recoil';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import FilledInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import { getMaritimeData } from "./Maritime/MaritimeInitialQuery";
import { ClickInfo, MaritimeData } from "./Maritime/MaritimeState";
import { isDataLoading, MaritimeAPIToken } from "./GlobalState";




let abortMaritimeData = new AbortController()

const apiOptions = ['Maritime', 'Aviation','DGV'];

const parameterOptions = {
    Maritime:['Name', 'MMSI', 'IMO', 'Call Sign'],
    Aviation:[],
    DGV:[]
}

const Search = ()=> {
    const [clickInfo, setClickInfo] = useRecoilState(ClickInfo);
    const [maritimeAPIToken] = useRecoilState(MaritimeAPIToken);
    const [maritimeData,setMaritimeData] = useRecoilState(MaritimeData);
    const [searchValue, setSearchValue] = React.useState('')
    const [parameterSelection, setParameterSelection] = React.useState('Name');
    const [apiSelection, setAPISelection] = React.useState('Maritime');
    const setIsLoading = useSetRecoilState(isDataLoading)

    let handleAPISelection = (event)=> {
        let select = event.target.value;
        setAPISelection(select)
    }
    
   
    
    let handleParameterSelection = (event)=> {
        let select = event.target.value;
        setParameterSelection(select)
    }

    let handleSearchValueChange = (event)=> {
        let select = event.target.value;
        setSearchValue(select)
    }

    let handleSearch = ()=>{
        switch (apiSelection){
            case 'Maritime':
                let searchParameters = {}


                if (parameterSelection == 'Name'){
                    searchParameters['name'] = searchValue;
                }

                if (parameterSelection == 'MMSI'){
                    searchParameters['mmsi'] = searchValue;
                }

                if (parameterSelection == 'IMO'){
                    searchParameters['imo'] = searchValue;
                }

                if (parameterSelection == 'Call Sign'){
                    searchParameters['callsign'] = searchValue; 
                }


                setIsLoading(true)
                getMaritimeData({setMaritimeData,abortMaritimeData,maritimeAPIToken,searchParameters,evd:true}).then(
                    (data)=> {
                      setIsLoading(false)
                      setClickInfo((prev)=>{
                        
                        let prevData = structuredClone(prev);
                        if (data.length >= 1){
                            return [...prevData, ...data]
                        } else {
                            return prevData
                        }
                        
                    })}
                )
            
            break;
        }
    }

return (<div style={{display:'flex', flexGrow:2, flexDirection:'row' }}>
          <FormControl sx={{minWidth:'120px',background:'white', borderRadius:'3px', display:'none' }} size='small'>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            displayEmpty
            value={apiSelection}
            onChange={handleAPISelection}
          >
            {apiOptions.map((d,i) => <MenuItem value={d} key={i}>{d}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl sx={{minWidth:'120px',background:'white', borderRadius:'3px' }} size='small'>
          <Select
            autoWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            displayEmpty
            value={parameterSelection}
            onChange={handleParameterSelection}
          >
            {parameterOptions[apiSelection].map((d,i) => <MenuItem value={d} key={i}>{d}</MenuItem>)}
          </Select>
        </FormControl>
          <FilledInput size='small' sx={{background:'white', borderRadius:'3px' }}
          fullWidth
          variant="filled"
          id="outlined-select-currency"
          color='info'
          value={searchValue}
          onChange={handleSearchValueChange}  
          endAdornment={
            <InputAdornment position="end">
              <IconButton
              onClick={handleSearch}
              >
                <SearchIcon/>
              </IconButton>
            </InputAdornment>
          }
        >
        </FilledInput>
        </div>)


}

export {Search}