import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {atom, selector, useRecoilState, useRecoilValue} from 'recoil';
import { MaritimeDataComponent } from './Maritime/MaritimeInitialQuery';
import { Snackbar } from '@mui/material';
import {PageInfo, MaritimeData, StopMaritimeData} from './Maritime/MaritimeState'
import Switch from '@mui/material/Switch';
import { AviationDataComponent } from './Aviation/AviationQuery';
import { DGVDataComponent } from './DGV/DGVQuery';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import {isDataLoading} from './GlobalState';

export default function DemoBottomBar() {
  const [pageInfo,setPageInfo] = useRecoilState(PageInfo);
  const isLoading = useRecoilValue(isDataLoading)


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
        {isLoading ? <LinearProgress /> : ''}
        <Toolbar variant="dense">
          <Stack direction='row' spacing={2}>
          <MaritimeDataComponent/>
          <AviationDataComponent/>
          <DGVDataComponent/>
          </Stack>
        </Toolbar>
      </AppBar>
      <Snackbar
                open={pageInfo.snackOpen}
                anchorOrigin={{vertical:'bottom','horizontal':'center'}}
                autoHideDuration={6000}
                onClose={()=> setPageInfo((state)=> {
                  state = structuredClone(state)
                  state.snackOpen = false
                  return state; 
                })}
                message={`Loading page ${pageInfo.count} of ${pageInfo.totalCount} / Record count: ${pageInfo.recordCount}`}
            />
    </Box>
  );
}