import { get , set } from 'idb-keyval';
import {useRef} from 'react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';


const indexedDBEffect = key => ({setSelf, onSet, trigger}) => {
  
  const loadPersisted = async () =>{ 
    const savedValue = await get(key)

    if (typeof(savedValue) != 'undefined') {
      setSelf(savedValue);
    }
  }

  if (trigger == 'get') {
    loadPersisted()
  }

  onSet((newValue, _, isReset) => {
    isReset
      ? set(key,null)
      : set(key, newValue);
  });
};

const localStorageEffect = key => ({setSelf, onSet}) => {
  const savedValue = localStorage.getItem(key)
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue, _, isReset) => {
    isReset
      ? localStorage.removeItem(key)
      : localStorage.setItem(key, JSON.stringify(newValue));
  });
};

const DeckLayers = atom({
    key: 'DeckLayers',
    default: [],
    dangerouslyAllowMutability: true
  });

  const CardPortal = atom({
    key: 'cardPortal',
    default: null,
  });

  const HoverInfo = atom({
    key: 'hoverInfo',
    default: {},
    dangerouslyAllowMutability: true
  });

  const INITIAL_VIEW_STATE = {
    longitude: 0,
    latitude: 20,
    zoom: 2,
    pitch: 0,
    bearing: 0
  };

  const ViewState = atom({
    key:'viewState',
    default: INITIAL_VIEW_STATE
  });

  const isDataLoading = atom({
    key:'isDataLoading',
    default: false
  });

  const MaritimeAPIToken = atom({
    key: 'maritimeAPIToken',
    default: '',
    effects:[
      localStorageEffect('MaritimeAPIToken')
    ]
  });

  const AviationAPIToken = atom({
    key: 'aviationAPIToken',
    default: '',
    effects:[
      localStorageEffect('AviationAPIToken')
    ]
  });

  const DGVAPIToken = atom({
    key: 'DGVAPIToken',
    default: '',
    effects:[
      localStorageEffect('DGVAPIToken')
    ]
  });

  export {DeckLayers, CardPortal, AviationAPIToken, MaritimeAPIToken, DGVAPIToken, HoverInfo, ViewState, isDataLoading, indexedDBEffect, localStorageEffect}
