import {
    RecoilRoot,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';
  import {PathLayer} from '@deck.gl/layers';


const getMaritimeHistoryData = async ({clickInfo, setMaritimeHistoryData, maritimeAPIToken})=>
    {
            let vesselList = clickInfo
            let historyList = {}
            let defaultDate = new Date(new Date().setDate((new Date().getDate() - 15))).toISOString();

            for (vessel of vesselList) {
                try {
                    let response = await fetch(`/vessels/mmsi_${vessel.staticData.mmsi}/positions?limit=5000&timestamp_after=${defaultDate}`, {
                        headers:{
                            Authorization: `Bearer ${maritimeAPIToken}`
                        }
                    });
                    let data = await response.json();
                    
                    let positions = []

                    for (position of data.data){
                        positions.push([position.geometry.coordinates[0], position.geometry.coordinates[1]])
                    }
                    historyList[vessel.staticData.mmsi] = {vessel, path:positions}
                    
                } catch (e){
                    console.log(`Error fetching Maritime history for vessel:${vessel} : ${e}`);
                }
            }
            console.log(historyList)
            setMaritimeHistoryData(historyList)
    }

const getMaritimeHistoryLayer = ({maritimeHistoryData, setClickInfo})=>{

    const layer = new PathLayer({
        id: 'maritime-history-layer',
        data: Object.values(maritimeHistoryData),
        pickable: true,
        widthScale: 20,
        widthMinPixels: 2,
          onClick: (info, event)=>{
            let record = structuredClone(info.object.vessel)
            setClickInfo((prev)=> [...prev.filter( d=>d.staticData.mmsi != record.staticData.mmsi), record]);
        },
        getPath: d => d.path,
        getColor: d => [0, 0, 0],
        getWidth: d => 5
    });

    return layer
}

export {getMaritimeHistoryData, getMaritimeHistoryLayer, MaritimeHistoryData}