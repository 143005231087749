import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {AviationAPIToken, MaritimeAPIToken, DGVAPIToken} from './GlobalState'
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';


let Settings = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [maritimeAPIToken, setMaritimeAPIToken] = useRecoilState(MaritimeAPIToken);
    const [aviationAPIToken, setAviationAPIToken] = useRecoilState(AviationAPIToken);
    const [dgvAPIToken, setDGVAPIToken] = useRecoilState(DGVAPIToken);



    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const handleAviationAPIChange = (event) => {
        setAviationAPIToken(event.target.value);
      };

    const handleMaritimeAPIChange = (event) => {
        setMaritimeAPIToken(event.target.value);
      };

    const handleDGVAPIChange = (event) => {
      setDGVAPIToken(event.target.value);
    };


    return (
    <Card sx={{minWidth:450}}>
      <CardContent>
      <Stack spacing={2}>
      <FormControl variant="outlined">
          <InputLabel htmlFor="maritimetoken">Maritime 2.0</InputLabel>
          <OutlinedInput
            id="maritimetoken"
            type={showPassword ? 'text' : 'password'}
            onChange={handleMaritimeAPIChange}
            value={maritimeAPIToken}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="aviationtoken">Aviation</InputLabel>
          <OutlinedInput
            id="aviationtoken"
            type={showPassword ? 'text' : 'password'}
            onChange={handleAviationAPIChange}
            value={aviationAPIToken}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="dgvtoken">DGV</InputLabel>
          <OutlinedInput
            id="dgvtoken"
            type={showPassword ? 'text' : 'password'}
            onChange={handleDGVAPIChange}
            value={dgvAPIToken}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        </Stack>
      </CardContent>
      <CardActions>
    </CardActions>
    </Card>)
  }

  export {Settings}